<template>
  <div class="side-bar">
    <ui-button
        @click="visibleSideBar = true"
        class="side-bar__activator"
        icon
        color="error"
    >
      <img src="../../assets/svg/sideBar/menu.svg" alt="" />
    </ui-button>
<!--    <img-->
<!--        @click="visibleSideBar = true"-->
<!--        class="side-bar__icon-mob"-->
<!--        src="../../assets/svg/menu-red.svg"-->
<!--        alt=""-->
<!--    >-->
    <transition name="slide-right">
      <div v-if="visibleSideBar" @click.self="visibleSideBar = false" class="side-bar__backdrop">
        <div class="side-bar__inner">
          <div class="side-bar__header">
            <div class="side-bar__header-wrap wrap">
              <img @click="visibleSideBar = false"
                   src="@/assets/svg/arrows/back.svg"
                   alt="">
              <div class="wrap__row">
                <router-link to="/notifications" @click.native="visibleSideBar = false">
                  <img src="@/assets/svg/icon-notification-red.png" alt="" style="width: 25px; height: 25px;" v-if="getRingColor === 'red'"/>
                  <img src="@/assets/svg/icon-notification-black.svg" alt="" v-if="getRingColor === 'black'"/>
                </router-link>
                <router-link style="display: flex" @click.native="visibleSideBar = false" to="/profile">
                  <img src="@/assets/svg/icon-settings-black.svg" alt="" />
                </router-link>
              </div>
            </div>
            <div class="side-bar__user user">
              <div class="user__wrap">
                <router-link to="/profile" @click.native="visibleSideBar = false">
                  <img :src="getUserAvatar(getUser.avatarSrc?.fileSrc)" class="user__avatar" alt="" />
                </router-link>
                <p class="user__name">{{ getTitle }}</p>
                <div class="user__row" v-if="getUser?.role">
                  <p class="user__rang">
                    <img :src="getRole.icon" alt="" />
                    {{ $t(getRole.text) }}
                  </p>
                  <div v-if="getUser.role === 'Company' || getUser.role === 'Government'" class="user__row-text">
                    {{ getUserName(getUser.lastName, getUser.firstName, getUser?.middleName) }}
                  </div>
                  <div v-if="getUser.role === 'Active'" class="user__row-text">
                    {{ $store.state.userRating[getUser.starRating] }}
                  </div>
                  <p class="user__info">
                    {{ getOrdersCount }} {{ $t('requests-1') }}
                  </p>
                </div>
                <div class="empty" v-else>{{ $t('empty') }}</div>
              </div>
              <div class="user__rating" v-if="getRole.visibleRating">
                <ui-rating v-model="getUser.starRating" size="25" />
                <span class="user__rating-status" @click="showScoreWindow" :style="{cursor: this.getUser?.role === 'Active' ? 'pointer' : ''}">{{ getUser.score }}</span>
              </div>
            </div>
          </div>
          <nav class="side-bar__nav">
            <accordion v-if="getUser.role === 'Admin'" :item="{ icon: require('@/assets/svg/sideBar/icon-side-bar-14.svg'), text: $t('administration') }">
              <template #body>
                  <router-link
                      v-for="(item,key) in data"
                      class="side-bar__nav-link"
                      :to="item.path"
                      @click.native="visibleSideBar = false"
                      :key="key"
                  >
                    {{ $t(item.text) }}
                  </router-link>
              </template>
            </accordion>
              <div v-for="(item, key) in getRole.sideBarNav" :key="key" class="">
                <accordion v-if="item.accordion" :item="{ icon: item.img, text: $t(item.text) }">
                  <template #body>
                    <router-link
                        v-for="(item,key) in dataEvents"
                        class="side-bar__nav-link"
                        :to="item.path"
                        @click.native="visibleSideBar = false"
                        :key="key"
                    >
                      {{ $t(item.text) }}
                    </router-link>
                  </template>
                </accordion>
                <router-link
                    v-else
                    :to="(getUser.status === 'Rejected' || getUser.status === 'New') && item.path === '/work-requests' ? '' : item.path"
                    :class="['side-bar__nav-link', {'disabled' : (getUser.status === 'Rejected' || getUser.status === 'New') && item.path === '/work-requests'}]"
                    @click.native="(getUser.status === 'Rejected' || getUser.status === 'New') && item.path === '/work-requests' ? '' : item.path ? visibleSideBar = false : ''"
                >
                  <img :src="item.img" alt="" />
                  {{ $t(item.text) }}
                </router-link>
              </div>
          </nav>
        </div>
      </div>
    </transition>
    <ui-alert v-model="visibleAlertScore">
      <div class="ui-alert__title">{{ $t('current-balance') }}</div>
      <div class="ui-alert__subtitle">
        <p>{{ getUser?.score}}<span>{{ $t('points') }}</span></p>
      </div>
      <div class="ui-alert__items">
        <div class="ui-alert__items-item item" v-for="(item, key) in getOrders" :key="key">
          <p class="item__date">{{ getFullDate(item.createdAt).slice(0, 10)}}</p>
          <div class="item__info">
            <div @click=" visibleAlertScore = false, $router.push(`/work-requests/${item.id}`)" class="item__info-left">{{ $t('request', {data: item.id}) }}<p>{{ $t('type-number', {data: item.type?.name}) }}</p></div>
            <p class="item__info-right">{{item.activeScore}}</p>
          </div>
        </div>
        <p class="ui-alert__items-show" @click="loadMore" v-if="visibleLoadMore">{{ $t('show-more') }}</p>
      </div>
    </ui-alert>
  </div>
</template>


<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import { getUserAvatar, getUserRole } from "@/utils/user";
import UiAlert from "@/components/ui/UiAlert.vue";
import {getFullDate} from "../../utils/general";

export default {
  name: "SideBarAuth",

  components: {
    UiAlert,
    Accordion: () => import('@/components/template/work-requests/ViewRequests/Accordion'),
    UiButton: () => import('@/components/ui/UiButton'),
    UiRating: () => import('@/components/ui/UiRating'),

  },

  data() {
    return {
      visibleSideBar: false,
      getUserAvatar,
      data: [
        { id: 1, text: 'manage-users', path: '/admin/users-management' },
        { id: 2, text: 'support-requests', path: '/admin/tech-support'  },
        { id: 3, text: 'rating-of-regions', path: '/admin/region'  },
        { id: 4, text: 'calculation-of-damage-directories', path: '/admin/informer/incident-types'  },
        { id: 4, text: 'calculation-of-regions-rating-directories', path: '/admin/region-informer/periods'  },
        { id: 5, text: 'application-documents', path: '/admin/about'  },
        { id: 6, text: 'change-log', path: '/admin/change-log'  },
        { id: 7, text: 'system-settings', path: '/admin/system-settings'  },
      ],
      dataEvents: [
        { id: 1, text: 'manage-events', path: '/event-management' },
        // { id: 2, text: 'Участники мероприятия', path: '/event-participants'  },
      ],
      allOrders: '',
      closedOrders: '',
      visibleAlertScore: false,
      visibleLoadMore: true
    }
  },

  methods: {
    ...mapActions(['notifications', 'reqGetOrders']),
    ...mapMutations(['setOrders']),
    getFullDate,
    getUserName(name, surname, middlename) {
      if(name && surname && middlename) {
        return name + ' ' + surname.split('')[0].toUpperCase() + '.' + middlename.split('')[0].toUpperCase() + '.'
      }else {
        return name + ' ' + surname.split('')[0].toUpperCase() + '.'
      }
    },

    getAdminOrders () {
      this.reqGetOrders({
        status: ['Accepted', 'Closed'],
        take: 500
      }).then((res) => {
        this.allOrders = res.meta.total
        this.closedOrders = res.meta.tabs.Closed || 0
      })
    },

    loadMore () {
      this.reqGetOrders({
        userId: this.getUser.id,
        skip: this.getOrders.length,
        take: 10,
      }).then((res) => {
        this.setOrders(this.$store.state.ordersStore.orders.concat(res.data))
        if(res.data.length !== 10) {
          this.visibleLoadMore = false
        }
      })
    },

    showScoreWindow () {
      if(this.getUser?.role === 'Active') {
        this.reqGetOrders({
          userId: this.getUser.id,
          skip: 0,
          take: 10,
        }).then((res) => {
          this.setOrders(res.data)
          this.visibleAlertScore = !this.visibleAlertScore
          if(res.data.length !== 10) {
            this.visibleLoadMore = false
          }
        })
      }
    },
  },

  computed: {
    ...mapGetters([
      'getUser',
      'getNotifications',
      'getOrders'
    ]),

    getRole() {
      console.log(getUserRole(this.getUser.role), 'data');
      return getUserRole(this.getUser.role)
    },

    getTitle() {
      return this.getUser.role === 'Inspector' || this.getUser.role === 'Lawyer' ? `${ this.getUserName(this.getUser.lastName, this.getUser.firstName, this.getUser.middleName) }`  : '' ||
          this.getUser.role === 'Company' ? this.getUser.companyName : '' ||
          this.getUser.role === 'Government' ? this.getUser.companyName : '' ||
          (this.getUser.role === 'Moderator' || this.getUser.role === 'Admin' || this.getUser.role === 'Active') ? this.getUser.login : ''
    },
    getRingColor () {
      return this.getNotifications.every(el => el.readAt !== null) ? 'black' : 'red'
    },

    getOrdersCount () {
      if(this.getUser?.role === 'Admin') {
        return `${this.closedOrders}/${this.allOrders}`
      } else {
        return `${this.getUser.totalOrdersCount - this.getUser.acceptedOrdersCount}/${this.getUser.totalOrdersCount}`
      }
    }
  },

  mounted () {
    this.notifications()
    setTimeout(() => {
      if(this.getUser?.role === 'Admin') {
        this.getAdminOrders()
      }
    }, 1000)
  },

  watch: {
    visibleSideBar: {
      handler () {
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.side-bar {

  //&__activator {
  //  @media (max-width: 768px) {
  //    display: none !important;
  //  }
  //}

  &__icon-mob {
    display: none;
    cursor: pointer;
    width: 32px;
    height: 32px;
    @media (max-width: 768px) {
      display: block;
    }
  }

  &__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: 0.3s;
    z-index: 100;
    background: rgba(153, 153, 153, 0.8);
    backdrop-filter: blur(2.5px);
    padding-right: 71px;
  }

  &__inner {
    position: relative;
    max-width: 406px;
    width: 100%;
    height: 100%;
    background: #F5F5F5;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
  }

  &__header {
    padding: 60px 20px 20px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
  }

  &__nav {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px 48px;
    overflow-y: auto;

    @media (max-width: 768px) {
      padding: 20px 30px;
    }

    ::v-deep .accordion__header {
      padding: 0;
    }

    &-link {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 13px;
      color: #343432;
      text-decoration: none;
    }

    .disabled {
      cursor: not-allowed;

      img {
        filter: grayscale(100%);
      }
    }
  }
}


.user {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  &__avatar {
    width: 70px;
    height: 70px;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(52, 52, 50, 0) 40.63%, #000000 100%);
    border: 1.14583px solid #E21F1F;
    border-radius: 114.583px;
    margin-bottom: 10px;
    object-fit: cover;
  }

  &__name {
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    color: #343432;
    margin-bottom: 8px;
  }

  &__rang {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    color: #9A9A9A;
    font-family: "Gotham Pro Regular";

    img {
      width: 10px;
      height: 10px;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    color: #9A9A9A;
    font-family: "Gotham Pro Regular";

    &::before {
      content: '';
      width: 4px;
      height: 4px;
      background: #9A9A9A;
      border-radius: 50%;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;

    &-text {
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 400;
      font-size: 12px;
      line-height: 11px;
      color: #9A9A9A;
      font-family: "Gotham Pro Regular";

      &:before {
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        background: #9A9A9A;
        border-radius: 50%;
      }
    }
  }

  &__rating {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-end;
    position: relative;

    ::v-deep .ui-rating {
      position: absolute;
      top: -40px;
    }

    &-status {
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      color: #FFFFFF;
      background: #E21F1F;
      box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
      border-radius: 200px;
      padding: 14px 14px 11px 14px;
    }
  }
}

.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #F5F5F5;
  margin-bottom: 34px;

  img {
    cursor: pointer;
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

::v-deep .link-left__text {
  font-size: 14px !important;
  line-height: 13px !important;
}

::v-deep .accordion__body {
  box-shadow: none;
  background: none;
}

::v-deep .accordion__body .side-bar__nav-link {
  &:before {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: black;
  }
  margin-left: 34px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    margin-left: 20px;
  }
}

::v-deep .accordion__body-inner {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.empty {
  padding: 0 !important;
}
::v-deep .ui-alert {
  background: #FFFFFF !important;
  padding: 40px 0 0 0 !important;
  align-items: flex-start !important;
  max-width: 406px !important;
  width: 100%;

  &__title {
    text-align: start !important;
    width: 100%;
    padding-left: 20px;
    font-size: 16px !important;
  }

  &__subtitle {
    color: #02AD21;
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    line-height: 112.2%;
    padding: 10px 20px;
    background-color: #F5F5F5;
    width: 100%;

    p {
      display: flex;
      align-items: center;
      gap: 10px;

      span {
        color: #1B1A1F;
        font-family: 'Gotham Pro Medium';
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-height: 400px;
    height: 100%;
    overflow-x: hidden;
    padding-bottom: 20px;

    .item {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &__date {
        color: #1B1A1F;
        font-size: 14px;
        font-weight: 400;
        margin-left: 20px;
      }

      &__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        background: #F5F5F5;

        &-left {
          display: flex;
          flex-direction: column;
          gap: 10px;
          color: #1B1A1F;
          font-size: 14px;
          font-weight: 400;
          cursor: pointer;

          p {
            font-family: 'Gotham Pro Regular';
            font-size: 12px;
            font-weight: 400;
          }
        }

        &-right {
          color: #02AD21;
          font-size: 20px;
          font-weight: 400;
        }
      }
    }

    &-show {
      color: #9A9A9A;
      font-size: 14px;
      font-weight: 400;
      margin-left: 20px;
      cursor: pointer;
    }
  }
}
</style>
