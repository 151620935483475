<template>
  <div class="nav-bar">
    <div class="nav-bar__title">
      <router-link tag="h1" to="/profile">{{ $t('profile') }}</router-link>
      <ui-menu v-model="visibleConfig">
        <template #activator>
          <img src="@/assets/svg/icon-settings-black.svg" alt="">
        </template>
        <template #body>
          <div class="config">
            <router-link @click.native="clickToLink" class="config__link" v-for="(link, index) in links"  :to="link.path" :key="index">
              {{ $t(link.text) }}
            </router-link>
            <p @click="visibleLogOutAlert = true; visibleConfig = false" class="config__link">{{ $t('log-out-of-profile') }}</p>
          </div>
        </template>
      </ui-menu>
    </div>
    <div class="nav-bar__links">
      <router-link
          v-for="(link, index) in links"
          :to="link.path"
          :key="index"
          class="nav-bar__links-link"
      >
        {{ $t(link.text) }}
      </router-link>
<!--      <router-link to="/profile/appoint-deputy" class="nav-bar__links-link" v-if="getUser.role !== 'Admin'">Назначить заместителя</router-link>-->
      <router-link to="/profile/confirmation-history" class="nav-bar__links-link" v-if="getUser?.status === 'Rejected'">{{ $t('confirmation-history-1') }}<img :src="require(`@/assets/svg/icon-attention-${attentionColor}.svg`)" alt="" /></router-link>
      <p @click="visibleLogOutAlert = true" class="nav-bar__links-link">{{ $t('log-out-of-profile') }}</p>
    </div>
    <UiSelectLanguages :options="getLanguages" v-model="language" :label="$t('language')"  class="nav-bar__languages" @input="changeLanguage"/>
    <div @click="visibleDeleteAccountAlert = true" class="nav-bar__delete-account">{{ $t('delete-profile') }}</div>
    <ui-alert v-model="visibleLogOutAlert">
      <div class="ui-alert__title">{{ $t('you-are-about-to-log-out-of-your-profile') }}</div>
      <div class="ui-alert__action">
        <ui-button @click="logout" color="error">{{ $t('log-out-of-profile') }}</ui-button>
        <ui-button @click="visibleLogOutAlert = false" color="white">{{ $t('cancel-1') }}</ui-button>
      </div>
    </ui-alert>
    <ui-alert v-model="visibleDeleteAccountAlert">
      <div class="ui-alert__wrap">
        <div class="ui-alert__title">{{ $t('do-want-to-delete-your-account') }}</div>
      </div>
      <div class="ui-alert__action">
        <ui-button @click="deleteAccount" color="error">{{ $t('delete-account') }}</ui-button>
        <ui-button @click="visibleDeleteAccountAlert = false" color="white">{{ $t('cancel-1') }}</ui-button>
      </div>
    </ui-alert>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import UiSelectLanguages from "@/components/ui/UiSelectLanguages.vue";
import { getCookie, setCookie } from "@/utils/cookie";
export default {
  name: "NavBar",
  components: {
    UiSelectLanguages,
    UiButton: () => import('@/components/ui/UiButton'),
    UiMenu: () => import('@/components/ui/UiMenu'),
    UiAlert: () => import('@/components/ui/UiAlert'),
  },

  data() {
    return {
      links: [
        { id: 0, text: 'edit-user-profile', path: '/profile/edit-profile' },
        { id: 1, text: 'change-password-1', path: '/profile/change-password' },
      ],
      visibleLogOutAlert: false,
      visibleDeleteAccountAlert: false,
      visibleConfig: false,
      language: null,
    }
  },

  computed: {
    ...mapGetters(['getUser', 'getLanguages']),

    attentionColor () {
      return this.$route.path === '/profile/confirmation-history' ? 'red' : 'grey'
    },
  },

  methods: {
    ...mapMutations(['setUserData']),
    ...mapActions([
        'reqLogout',
        'reqDeleteAccount',
        'languages'
    ]),

    logout() {
      this.reqLogout()
        .then(() => {
          this.visibleLogOutAlert = false
          setTimeout(() => {
            this.$router.push('/')
            this.setUserData(null)
          },300)
        })
    },

    deleteAccount() {
      this.reqDeleteAccount()
        .then(() => {
          this.visibleDeleteAccountAlert = false
          setTimeout(() => {
            this.$router.push('/')
          },300)
        })
    },

    clickToLink() {
      this.visibleConfig = false
    },

    changeLanguage () {
      this.$i18n.locale = this.language.code
      setCookie('locale', this.language.code)
      location.reload();
    }
  },

  created() {
    this.languages().then(() => {
      this.getLanguages.forEach(el => {
        if(el.code === getCookie('locale')) {
          this.language = el
        }
      })
    })
  }
}
</script>

<style lang="scss" scoped>
  .nav-bar {
    position: relative;

    &__title {
      margin-bottom: 40px;

      h1 {
        width: fit-content;
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        color: #343432;
        cursor: pointer;
      }

      img {
        cursor: pointer;
        width: 32px;
        height: 32px;

        @media (min-width: 993px) {
          display: none;
        }
      }

      @media (max-width: 992px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
      }
    }

    &__links {
      display: flex;
      flex-direction: column;
      gap: 30px;

      @media (max-width: 992px) {
        display: none;
      }

      &-link {
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
        color: #343432;
        cursor: pointer;
        display: flex;
        align-items: center;
        white-space: nowrap;
        gap: 5px;
      }

      .router-link-active {
        color: red;
      }
    }

    &__languages {
      bottom: 20px;
      left: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      cursor: pointer;
      color: #9A9A9A;
      position: absolute !important;

      @media (max-width: 1200px) {
        display: none !important;
      }
    }

    &__delete-account {
      bottom: 0;
      left: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      cursor: pointer;
      color: #9A9A9A;
      position: absolute;

      @media (max-width: 1200px) {
        display: none;
      }
    }

    .config {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px;
      background: #FFFFFF;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
      border-radius: 4px;

      &__link {
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
        color: #343432;
        cursor: pointer;
        white-space: nowrap;
      }

      .router-link-active {
        color: red;
      }
    }
  }
</style>
